import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "../stores/AuthStore";
import Loader from "../components/Loader";

const Fed = (props) => {
  const { role } = useAuthStore();
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [shippingDetails, setShippingDetails] = useState({});
  const [loader, setLoader] = useState("");
  const [stats, setStats] = useState({});

  useEffect(() => {
    getOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, filter, limit, search]);

  const getOrders = async () => {
    await axios
      .get(
        server +
          "/api/v1/fed/readAllOrders?page=" +
          page +
          "&limit=" +
          limit +
          "&sort=" +
          sort +
          "&filter=" +
          filter +
          "&search=" +
          search,
        config
      )
      .then((res) => {
        setOrders(res.data.docs);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.totalDocs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateOrder = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const formData = new FormData();
    formData.append("file", e.target.file.files[0]);
    formData.append("status", shippingDetails.status);
    formData.append("note", shippingDetails.note);
    formData.append("price", e.target.price.value);

    await axios
      .post(
        server + "/api/v1/fed/updateOrder/" + shippingDetails._id,
        formData,
        config
      )
      .then((res) => {
        toast.success("Order Updated Successfully");
        getOrders();
        readStats();
        setLoader("");
      });
  };

  const readStats = async () => {
    await axios
      .get(server + "/api/v1/fed/read-stats", config)
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between mb-3">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Fedex Orders</h3>
                  </div>
                </div>

                <div className="row g-gs ">
                  <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                    <div className="card" style={{ borderRadius: "0.8rem" }}>
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h6 className="text-gray">Pending</h6>
                            <h5 className="py-2">{stats.pending}</h5>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="icon ni ni-cart"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                    <div className="card" style={{ borderRadius: "0.8rem" }}>
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h6 className="text-gray">Completed</h6>
                            <h5 className="py-2">{stats.completed}</h5>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="icon ni ni-cart"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                    <div className="card" style={{ borderRadius: "0.8rem" }}>
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h6 className="text-gray">Cancelled</h6>
                            <h5 className="py-2">{stats.cancelled}</h5>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="icon ni ni-cart"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-4">
                          <div className="d-md-flex justify-content-end align-items-center">
                            <div className="d-md-flex justify-content-center align-items-center">
                              {/* .search */}
                              <div className="col mt-3">
                                <div className="form-group mr-3 ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">OrderId</th>
                                  <th scope="col">User ID</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Created At</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order, index) => (
                                  <tr key={order._id}>
                                    <td>{index + 1 + (page - 1) * limit}</td>
                                    <td>{order.uuid}</td>

                                    <td>
                                      {role === "admin"
                                        ? order.user?.discordId
                                        : "***"}
                                    </td>
                                    <td>{"$" + order.price}</td>
                                    <td>
                                      {order.status === "Pending" ? (
                                        <span className="badge badge-warning">
                                          Pending
                                        </span>
                                      ) : order.status === "Completed" ? (
                                        <span className="badge badge-success">
                                          Completed
                                        </span>
                                      ) : order.status ===
                                        "Awaiting User Input" ? (
                                        <span className="badge badge-info">
                                          Awaiting User Input
                                        </span>
                                      ) : order.status === "In Process" ? (
                                        <span className="badge badge-info">
                                          In Process
                                        </span>
                                      ) : (
                                        <span className="badge badge-danger">
                                          Cancelled
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {new Date(order.createdAt).toString()}
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center w-100">
                                        <button
                                          className="btn btn-sm btn-primary mr-2"
                                          onClick={() => {
                                            setShippingDetails(order);
                                          }}
                                          data-toggle="modal"
                                          data-target="#view"
                                        >
                                          <em className="icon ni ni-eye"></em>
                                        </button>

                                        <button
                                          className="btn btn-sm btn-warning"
                                          data-toggle="modal"
                                          data-target="#update"
                                          onClick={() => {
                                            setShippingDetails(order);
                                          }}
                                        >
                                          <em className="icon ni ni-edit"></em>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* update Order Modal */}
      <div
        id="update"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update Fedex Order
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={updateOrder}>
                <div className="form-group mb-3">
                  <label className="form-label">Staus</label>
                  <select
                    name="status"
                    id="status"
                    className="form-control"
                    value={shippingDetails.status}
                    onChange={(e) => {
                      setShippingDetails({
                        ...shippingDetails,
                        status: e.target.value,
                      });
                    }}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Awaiting User Input">
                      Awaiting User Input
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">Note</label>
                  <textarea
                    className="form-control"
                    name="note"
                    value={shippingDetails.note}
                    onChange={(e) => {
                      setShippingDetails({
                        ...shippingDetails,
                        note: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label className="form-label">Price</label>
                  <input
                    type="text"
                    className="form-control"
                    name="price"
                    defaultValue={shippingDetails.price}
                  />
                </div>
                <div className="form-group mb-3">
                  <label>File</label>
                  <input type="file" className="form-control" name="file" />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* view Order Modal */}
      <div
        id="view"
        className="modal fade "
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                View Order
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                {/* Sender Information */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h6>Sender Information</h6>
                        <p>
                          <strong className="fw-bold text-muted">Name:</strong>{" "}
                          {shippingDetails?.from?.name}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Street:
                          </strong>{" "}
                          {shippingDetails?.from?.street}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Additional Street:
                          </strong>{" "}
                          {shippingDetails?.from?.street2}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">City:</strong>{" "}
                          {shippingDetails?.from?.city}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">State:</strong>{" "}
                          {shippingDetails?.from?.state}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            ZIP Code:
                          </strong>{" "}
                          {shippingDetails?.from?.zip}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Receiver Information */}
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h6>Receiver Information</h6>
                        <p>
                          <strong className="fw-bold text-muted">Name:</strong>{" "}
                          {shippingDetails?.to?.name}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Street:
                          </strong>{" "}
                          {shippingDetails?.to?.street}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Additional Street:
                          </strong>{" "}
                          {shippingDetails?.to?.street2}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">City:</strong>{" "}
                          {shippingDetails?.to?.city}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">State:</strong>{" "}
                          {shippingDetails?.to?.state}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            ZIP Code:
                          </strong>{" "}
                          {shippingDetails?.to?.zip}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Package Details */}
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h6>Package Details</h6>
                        <p>
                          <strong className="fw-bold text-muted">
                            Weight:
                          </strong>{" "}
                          {shippingDetails.weight}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Dimensions:
                          </strong>{" "}
                          {`${shippingDetails.length} x ${shippingDetails.width} x ${shippingDetails.height} ${shippingDetails.dimensionUnit} (L x W x H)`}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Weight Unit:
                          </strong>{" "}
                          {shippingDetails.weightUnit}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Special Notes:
                          </strong>{" "}
                          {shippingDetails.specialNotes}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Fed;
